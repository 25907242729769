:root {
  --jexcel_header_color: #000;
  --jexcel_header_color_highlighted: #000;
  --jexcel_header_background: #f3f3f3;
  --jexcel_header_background_highlighted: #dcdcdc;
  --jexcel_content_color: #000;
  --jexcel_content_color_highlighted: #000;
  --jexcel_content_background: #fff;
  --jexcel_content_background_highlighted: rgba(0, 0, 0, 0.05);
  --jexcel_menu_background: #fff;
  --jexcel_menu_background_highlighted: #ebebeb;
  --jexcel_menu_color: #555;
  --jexcel_menu_color_highlighted: #555;
  --jexcel_menu_box_shadow: 2px 2px 2px 0px rgba(143, 144, 145, 1);
  --jexcel_border_color: #ccc;
  --jexcel_border_color_highlighted: #000;
  --active_color: #007aff;
}

.jexcel {
  border-bottom: 1px solid var(--jexcel_border_color);
  border-right: 1px solid var(--jexcel_border_color);
}

.jexcel > tbody > tr > td,
.jexcel > thead > tr > td {
  border-top: 1px solid var(--jexcel_border_color);
  border-left: 1px solid var(--jexcel_border_color);
  background-color: var(--jexcel_content_background);
  color: var(--jexcel_content_color);
}

.jexcel > tbody > tr > td:first-child,
.jexcel > thead > tr > td {
  background-color: var(--jexcel_header_background);
  color: var(--jexcel_header_color);
}

.jexcel > thead > tr > td.selected,
.jexcel > tbody > tr.selected > td:first-child {
  background-color: var(--jexcel_header_background_highlighted);
  color: var(--jexcel_header_color_highlighted);
}

.jexcel > tbody > tr > td.jexcel_cursor a {
  color: var(--active-color);
}

.jexcel_pagination > div > div {
  color: var(--jexcel_header_color);
  background: var(--jexcel_header_background);
  border: 1px solid var(--jexcel_border_color);
}

.jexcel_page,
.jexcel_container input,
.jexcel_container select {
  color: var(--jexcel_header_color);
  background: var(--jexcel_header_background);
  border: 1px solid var(--jexcel_border_color);
}

.jexcel_contextmenu {
  border: 1px solid var(--jexcel_border_color);
  background: var(--jexcel_menu_background);
  color: var(--jexcel_menu_color);
  box-shadow: var(--jexcel_menu_box_shadow);
  -webkit-box-shadow: var(--jexcel_menu_box_shadow);
  -moz-box-shadow: var(--jexcel_menu_box_shadow);
}

.jexcel_contextmenu > div a {
  color: var(--jexcel_menu_color);
}

.jexcel_contextmenu > div:not(.contextmenu-line):hover a {
  color: var(--jexcel_menu_color_highlighted);
}

.jexcel_contextmenu > div:not(.contextmenu-line):hover {
  background: var(--jexcel_menu_background_highlighted);
}

.jexcel_dropdown .jdropdown-container,
.jexcel_dropdown .jdropdown-content {
  background-color: var(--jexcel_content_background);
  color: var(--jexcel_content_color);
}

.jexcel_dropdown .jdropdown-item {
  color: var(--jexcel_content_color);
}

.jexcel_dropdown .jdropdown-item:hover,
.jexcel_dropdown .jdropdown-selected,
.jexcel_dropdown .jdropdown-cursor {
  background-color: var(--jexcel_content_background_highlighted);
  color: var(--jexcel_content_color_highlighted);
}

.jexcel .jcalendar-content {
  background-color: var(--jexcel_header_background);
  color: var(--jexcel_header_color);
}

.jexcel .jcalendar-content > table {
  background-color: var(--jexcel_content_background);
  color: var(--jexcel_content_color);
}

.jexcel .jcalendar-weekday {
  background-color: var(--jexcel_content_background_highlighted);
  color: var(--jexcel_content_color_highlighted);
}

.jexcel .jcalendar-sunday {
  color: var(--jexcel_header_color);
}

.jexcel .jcalendar-selected {
  background-color: var(--jexcel_content_background_highlighted);
  color: var(--jexcel_content_color_highlighted);
}

.jexcel_toolbar i.jexcel_toolbar_item {
  color: var(--jexcel_content_color);
}

.jexcel_toolbar i.jexcel_toolbar_item:hover {
  background: var(--jexcel_content_background_highlighted);
  color: var(--jexcel_content_color_highlighted);
}

.jexcel_toolbar {
  background: var(--jexcel_header_background);
}

.jexcel_content::-webkit-scrollbar-track {
  background: var(--jexcel_background_head);
}

.jexcel_content::-webkit-scrollbar-thumb {
  background: var(--jexcel_background_head_highlighted);
}

.jexcel_border_main {
  border: 1px solid #000;
  border-color: var(--jexcel_border_color_highlighted);
}

.jexcel .highlight {
  background-color: var(--jexcel_content_background_highlighted);
}

.jexcel .highlight-bottom {
  border-bottom: 1px solid var(--jexcel_border_color_highlighted);
}
.jexcel .highlight-right {
  border-right: 1px solid var(--jexcel_border_color_highlighted);
}
.jexcel .highlight-left {
  border-left: 1px solid var(--jexcel_border_color_highlighted);
}
.jexcel .highlight-top {
  border-top: 1px solid var(--jexcel_border_color_highlighted);
}
.jexcel .copying-top {
  border-top-color: var(--jexcel_border_color_highlighted);
}
.jexcel .copying-right {
  border-right-color: var(--jexcel_border_color_highlighted);
}
.jexcel .copying-left {
  border-left-color: var(--jexcel_border_color_highlighted);
}
.jexcel .copying-bottom {
  border-bottom-color: var(--jexcel_border_color_highlighted);
}
.jexcel_border_main,
.jexcel .highlight-top.highlight-left,
.jexcel .highlight-top,
.jexcel .highlight-left {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}
